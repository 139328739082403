.wrapper {
  background-color: white;
  box-shadow: var(--boxShadow);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  position: relative;
  border-top: 1px solid #d7d7d7;
}

.item {
  border-bottom: 0px solid;
  transition: var(--transitionStyleButton);

  white-space: nowrap;
  padding: 0 20px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  min-height: 50px;
  scroll-snap-align: center;
}

.itemHovered {
  /* border-bottom: 5px solid var(--marketplaceColor); */
  background-color: var(--marketplaceColorDark);
  color: var(--colorWhite);
}

.subcategoriesWrapper {
  position: absolute;
  width: 100%;
  background-color: white;
  box-shadow: var(--boxShadow);
  border-top: 1px solid #d7d7d7;
  /* bottom: -1px; */
  bottom: 0;
  /* transform: translateY(98%); */
  transform: translateY(100%);
  z-index: 999;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  padding: 20px;
}

.subcategoryItem {
  margin-right: 20px;
  margin-bottom: 10px;
  cursor: pointer;
  color: var(--marketplaceColor);
  min-width: 18%;

  &:hover {
    transform: scale(1.03);
  }
}
