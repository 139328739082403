.root {
  width: 100%;
  background-color: var(--matterColorLight);
  z-index: var(--zIndexTopbar);
  position: fixed;
}

.absoluteRoot {
  @media (--viewportMedium) {
    position: absolute;
    background-color: transparent;
  }
}
