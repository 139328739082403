.favButtonWrapper {
  position: absolute;
  top: 10px;
  right: 10px;
  margin: auto 0;
  z-index: 5;

  /* @media only screen and (max-width: 900px) {
    padding: 0 24px;
  } */

  @media only screen and (min-width: 900px) {
    margin-left: 2pc;
  }
}

.favButtonWrapperLeft {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 5;

  @media only screen and (max-width: 900px) {
    padding: 0 24px;
  }
}

.favButtonNotSelected {
  cursor: pointer;
  display: block;

  transform: scale(1.5);

  &:hover {
    transform: scale(1.6);
    filter: invert(18%) sepia(96%) saturate(6390%) hue-rotate(332deg) brightness(93%) contrast(108%);
  }
}

.titleWrapper {
  display: flex;
  flex-direction: row;
}

.favButtonSelected {
  cursor: pointer;
  display: block;
  filter: invert(18%) sepia(96%) saturate(6390%) hue-rotate(332deg) brightness(93%) contrast(108%);

  transform: scale(1.5);

  &:hover {
    transform: scale(1.6);
  }
}