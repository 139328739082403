.wrapper {
  position: relative;
  width: 100%;
  min-height: 60px;
  background-color: var(--colorWhite);
  border-bottom: 2px solid rgb(197, 197, 197, 0.2);
  border-top: 2px solid rgb(197, 197, 197, 0.2);

  padding: 5px 5px 5px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (min-width: 768px) {
    padding-right: 0;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
}

.wrapperLeft {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 10vw;
  width: 100%;
  text-transform: uppercase;
  & button {
    text-transform: uppercase;
  }
  @media (max-width: 768px) {
    order: 2;
    display: none;
  }
}

.wrapperRight {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: 768px) {
    order: 1;
    justify-content: center;
    width: 100%;
    align-items: center;
  }
}

.newListingLink {
  margin-right: 24px;
  background-color: var(--marketplaceColor);
  color: white;
  border-radius: 50px;
  padding: 10px 30px;

  font-size: 12px;
  line-height: 24px;
  font-family: var(--fontFamily);
  font-weight: var(--fontWeightMedium);

  @media (max-width: 768px) {
    margin: 0;
    width: 100%;
    text-align: center;
  }

  @media (min-width: 768px) {
    position: absolute;
    right: 20px;
    top: 15%;
    margin: 0;
    height: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &:hover {
    text-decoration: none;
    background-color: var(--marketplaceColorDark);
  }
}

.profileMenuLabel {
  border-bottom: 0px solid;
  transition: var(--transitionStyleButton);

  flex-shrink: 0;
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0;
  padding: 0 12px 0 12px;

  &:hover {
    border-bottom: 4px solid var(--marketplaceColor);
    border-radius: 0;
    text-decoration: none;
  }

  &:active {
    border-bottom: 0;
  }
}

.profileMenuIsOpen {
  &:hover {
    border-bottom: 0;
  }
}

.menuLabel {
  color: var(--matterColor);
  font-size: 12px;
  line-height: 24px;
  font-weight: var(--fontWeightMedium);
}

.link {
  margin: 0 10px;
  /* color: var(--matterColor);
  font-size: 16px;
  line-height: 24px;
  font-weight: var(--fontWeightMedium); */
  padding: 0;
  width: unset;
  /* &:hover {
    border-bottom: 4px solid var(--marketplaceColor);
    border-radius: 0;
    text-decoration: none;
  } */

  color: var(--colorGrey700);
  font-size: 12px;
  line-height: 24px;
  font-family: var(--fontFamily);
  font-weight: var(--fontWeightMedium);
  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }
}

.profileMenuContent {
  min-width: 276px;
  padding-top: 20px;
}
.longMenuContent {
  padding-top: 20px;
  width: 60vw;
  display: flex;
}

.menuOptionLong {
  width: 100px;
  text-align: start;

  color: var(--colorGrey700);
  font-size: 12px;
  line-height: 24px;
  font-family: var(--fontFamily);
  font-weight: var(--fontWeightMedium);
  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }
}
.doubleMenuWrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 0 20px 20px 20px;
  max-height: 280px;
  min-width: 450px;
  width: 100%;
}

.menuOption {
  /* color: var(--colorGrey700); */
  width: 50%;
  text-align: start;
  /* &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  } */

  color: var(--colorGrey700);
  font-size: 12px;
  line-height: 24px;
  font-family: var(--fontFamily);
  font-weight: var(--fontWeightMedium);
  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }
}

.singleMenuWrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 0 20px 20px 20px;
  min-width: 200px;
}

.singleMenuOption {
  /* color: var(--colorGrey700); */
  width: 100%;
  text-align: start;
  /* &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  } */
  color: var(--colorGrey700);
  font-size: 12px;
  line-height: 24px;
  font-family: var(--fontFamily);
  font-weight: var(--fontWeightMedium);
  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }
}
