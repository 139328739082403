@import '../../../styles/customMediaQueries.css';

.root {
  /* Expand to the full remaining width of the viewport */
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (--viewportMedium) {
  margin-top: 60px;
   
  }
  @media (--viewportLarge) {
    margin-top:5rem;
     
    }
}
